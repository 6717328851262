@import 'src/styles/variables';
@import 'src/styles/mixins';

.advantages {
  @include verticalGap(16px);
}

.advantage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  flex-shrink: 0;

  rect {
    fill: $purple50;
  }

  path {
    fill: $purple400;
  }
}

.advantageLabel {
  font-size: 14px;
  line-height: 160%;
  color: $black100;
}

.content {
  @include verticalGap(24px);
}

.buttonContainer {
  @include verticalGap(20px);
}

.feeBreakdown {
  @include verticalGap(16px);
  max-width: 300px;
  margin: 0 auto;

  h2 {
    @include body-text('lg', $boldFontWeight);
  }
}

.feeRow {
  display: flex;
  gap: auto;
  @include body-text('md', $regularFontWeight);
  color: $black60;
  justify-content: space-between;
  width: 300px;
  text-align: center;

  &.heading {
    @include body-text('md', $boldFontWeight);
    color: $black100;
  }
}

.expanderContainer {
  text-align: left;
  font-size: 12px;
  border: 1px solid $black10;
  box-shadow: $deepBoxShadow;
  border-radius: 12px;
  margin-bottom: 24px;
}

.expanderOpen {
  border-bottom: 1px solid $black6;
  padding: 0 16px 16px;
  display: flex;
}

.expanderTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: $black100;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  padding: 32px 16px;
  gap: 16px;

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.savingsDetail {
  @include verticalGap(16px);
}

.chartWrapper {
  width: 100%;
  height: 200px;
}

.legend {
  display: flex;
  gap: 16px;
  color: $black100;

  .item {
    display: flex;
    gap: 4px;
    align-items: center;

    .bullet {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $black20;
    }

    &.highlighted .bullet {
      background-color: $purple500;
    }

    .content {
      @include verticalGap(0);

      .title {
        @include body-text('xs', $semiBoldFontWeight);
      }

      .value {
        @include body-text('xs', $regularFontWeight);
      }
    }
  }
}
